import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";
import CookieConsent from "react-cookie-consent";
import BottomSILogo from "../components/BottomSILogo";
import { Nav } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import useContentful from "../../../hooks/useContentful";
import packageJson from "../../../../package.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../App.css";
import config from "../../../config";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const enableEloyalty = process.env.REACT_APP_ENABLE_ELOYALTY;

const theme = createTheme();

export const Mobile = () => {
  const [view, setView] = useState([]);
  const { getHomeView } = useContentful();
  const [bgUrl, setBgUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [slogan, setSlogan] = useState("");
  const [welcomeMsg, setwelcomeMsg] = useState("");
  const [welcomeMsg2, setwelcomeMsg2] = useState("");
  const [button1, setbutton1] = useState("");
  const [button2, setbutton2] = useState("");
  const [button2SignedIn, setbutton2SignedIn] = useState("");
  const [button3, setbutton3] = useState("");

  useEffect(() => {
    getHomeView().then((response) => {
      const item = response[0];
      setBgUrl(item.backgroundImage.fields.file.url);
      setLogoUrl(item.logo.fields.file.url);
      setSlogan(item.slogan);
      setwelcomeMsg(item.welcomeMessage);
      setwelcomeMsg2(item.welcomeMessage2);
      setbutton1(item.button1);
      setbutton2(item.button2);
      setbutton2SignedIn(item.button2SignedIn);
      setbutton3(item.button3);
      // setView(response);
    });
  });

  const history = useHistory();
  const [cookieSIC, cookieSICName, setCookieSICName, removeCookieSICName] =
    useCookies(["sic_name", "sic_user_id", "sic_firstName"]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  useEffect(() => {
    var verifyOrderProcess = new EventSource(
      "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/" +
        siteId +
        "/" +
        getCookie("orderId") +
        "?server_id=" +
        serverId
    );
    verifyOrderProcess.onmessage = function (event) {
      if (event.data === 1) {
        verifyOrderProcess.close();
        setCookie("orderId", "");
      }
    };

    var sourcePWA = new EventSource(
      "https://ecommv2.servingintel.com/pwa/check_update"
    );
    sourcePWA.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (
          sessionStorage.getItem("pwa_update_date_time") !== u_items[0].time
        ) {
          caches.delete("appV1");
          sessionStorage.setItem("pwa_update_date_time", u_items[0].time);
          window.location.reload();
        }
      }
    };
  }, []);

  const [landingContent, setlandingContent] = useState("");

  useEffect(() => {
    const landingContentText = config.landingcontent;

    setlandingContent(landingContentText);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          fontSize: "${newFontSize}px",
          height: "100vh",
          backgroundImage: `url(${siteMedSrc}background.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{tagline}</title>
            <link rel="canonical" href="/" />
            <meta
              name="description"
              content={`${landingContent.description}`}
            />
            <meta name="keywords" content={`${landingContent.keywords}`} />
            <meta
              property="og:title"
              content={`${restaurantName}: ${landingContent.title}`}
            />
            <meta
              property="og:description"
              content={`${landingContent.description}`}
            />
          </Helmet>
        </HelmetProvider>
        <Grid
          className="center"
          xs={12}
          style={{ padding: "0 15px", height: "35vh" }}
        >
          <Typography
            className="white padding-bottom-10 padding-top-20"
            variant="h5"
            gutterBottom
            component="div"
          >
            {cookieSIC.sic_firstName
              ? "Hello, " + cookieSIC.sic_firstName
              : "Hi there"}
            !{" "}
            {cookieSIC.sic_firstName ? (
              <span>Welcome back to</span>
            ) : (
              <span>Welcome to</span>
            )}
            {/* {view.map((item) => (
            <Grid item key={item.id}>
              <h2>{item.slogan}</h2>
            </Grid>
          ))}
            {cookieSIC.sic_firstName ? "Hello, " + cookieSIC.sic_firstName: "Hi there"}! {" "}
            {cookieSIC.sic_firstName ? (
              <span>{welcomeMsg2}</span>
              ) : (
                <span>{welcomeMsg}</span>
            )} */}
          </Typography>
          <img
            width={logoWidth}
            height="100%"
            title={`${restaurantName} Logo`}
            className="App-logo title"
            src={siteMedSrc + "logo" + ".png"}
            alt={`${restaurantName} Logo`}
            loading="lazy"
          />
          <Typography
            className="white padding-bottom-10"
            variant="h5"
            gutterBottom
            component="div"
          >
            {/* {slogan} */}
            {tagline}
          </Typography>
        </Grid>
        <div className="dept-btn-cont center">
          <Col
            xs={12}
            onClick={() => history.push("/menu")}
            style={{ cursor: "pointer", display: "none" }}
          >
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-1">
                  <img
                    title="Order Now"
                    loading="lazy"
                    src={siteMedSrc + "order-nowicon-01" + ".svg"}
                    alt="Order Now"
                    width="72"
                    height="auto"
                  />
                  <Typography component="h3" variant="h3" className="white">
                    Order Now
                  </Typography>
                </div>
              </div>
            </div>
          </Col>
          {enableEloyalty === "1" ? (
            <Col xs={12}>
              <div className="col-lg-12">
                <div className="our-dept-main">
                  <div className="dept-front dept-2">
                    <img
                      title={`Join ${restaurantName} Club`}
                      loading="lazy"
                      src={siteMedSrc + "join-eloyalty-01" + ".svg"}
                      alt={`Join ${restaurantName} Club`}
                      width="72"
                      height="auto"
                    />
                    <Typography component="h3" variant="h3" className="white">
                      <Nav.Link
                        onClick={() => {
                          history.push(
                            cookieSIC.sic_user_id > 0 ? "/rewards" : "/register"
                          );
                        }}
                      >
                        {cookieSIC.sic_user_id > 0
                          ? `View ${restaurantName} Club`
                          : `Join ${restaurantName} Club`}
                      </Nav.Link>
                    </Typography>
                  </div>
                </div>
              </div>
            </Col>
          ) : null}
          <Col
            xs={12}
            onClick={() => history.push("/home")}
            style={{ cursor: "pointer" }}
          >
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-3">
                  <img
                    title="Visit Our Website"
                    loading="lazy"
                    src={siteMedSrc + "visit-our-website-01" + ".svg"}
                    alt="Visit Our Website"
                    width="72"
                    height="auto"
                  />
                  <Typography component="h3" variant="h3" className="white">
                    Visit Our Website
                  </Typography>
                </div>
              </div>
            </div>
          </Col>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept all cookies"
          cookieName="myAwesomeCookieName2"
          buttonClasses="btn btn-red"
          style={{ background: "#000" }}
          buttonStyle={{ fontSize: "16px" }}
          expires={365}
        >
          <div className="center">
            <Col xs={3} className="padding-bottom-20 center">
              <img
                width="100%"
                height="100%"
                title="Accept all cookies Icon"
                src={siteMedSrc + "cookie-icon" + ".svg"}
                size="15"
                alt="Accept all cookies Icon"
                loading="lazy"
              />
            </Col>
            <p>
              <h3 className="center"> Your Privacy </h3>
            </p>
          </div>
          <Typography>
            Greetings! Our website uses cookies so we may better serve you. By
            clicking “Accept all cookies” and by continuing to browse our site
            you are agreeing to our{" "}
          </Typography>
          <Typography>
            <a
              href="#"
              onClick={() => {
                history.push("/terms-and-conditions");
              }}
            >
              Terms and Conditions
            </a>
          </Typography>
        </CookieConsent>
        <BottomSILogo />
        <div className="center">
          <div
            style={{
              position: "fixed",
              bottom: "5px",
              lineHeight: "1",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "1000",
              minWidth: "500px",
            }}
            className="col-lg-12 white"
          >
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ color: "white" }}
            >
              © {new Date().getFullYear()} {restaurantName} · Version{" "}
              {packageJson.version}
            </Typography>
          </div>
        </div>
      </Grid>
    </ThemeProvider>
  );
};
