import React, { useEffect, useState } from "react";
import { Typography, Grid, Container, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-time-picker/assets/index.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { setSelectedAddress } from "../../redux/publicReducer/actions";
import { useHistory } from "react-router-dom";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import { useCookies } from "react-cookie";
import axios from "axios";
import Swal from "sweetalert2";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);

const useStyles = makeStyles((theme) => ({
  buttonCheckOut: {
    cursor: "pointer",
    color: "#FFF",
    background: "#005CB9",
    display: "flex",
    margin: "15px 0px !important",
    padding: "15px",
    textAlign: "center",
    width: "100%",
    borderRadius: "10px !important",
    textTransform: "Capitalize",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#16497c",
    },
  },
  buttonCursor: {
    cursor: "pointer",
  },
}));

export default function CheckoutMini(props) {
  const classes = useStyles();
  const [openTips, setOpenTips] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
  } = useSelector((state) => state.public);
  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/tax/" +
      siteId +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        setTax(
          parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) / 100
        );
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  //console.log(cookiePayment, "COOKIE");
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        "https://ecommv2.servingintel.com/payment/coupon/" +
        serverId +
        "/" +
        siteId +
        "/" +
        tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept) {
                  totalDiscountPrice =
                    parseFloat(totalDiscountPrice) +
                    parseFloat((item.DefaultPrice / 100) * item.quantity);
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept) {
                totalDiscountPrice =
                  parseFloat(totalDiscountPrice) +
                  parseFloat((item.DefaultPrice / 100) * item.quantity);
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <React.Fragment>
      <Container
        style={{
          position: "relative",
          // height: "90vh",
          // maxHeight: "90vh",
          /*overflowY: "auto",*/
          backgroundColor: "transparent",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {/* <Typography
          variant="subtitle1"
          style={{ fontWeight: 800 }}
          gutterBottom
        >
          MY ORDER{" "}
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              fontSize: "0.8rem",
            }}
            onClick={() => history.push("/menu")}
          >
            Add More
          </Button>
        </Typography> */}
        <Box height="0rem" />
        <Grid container spacing={1} style={{ overflow: "auto" }}>
          {cartItems.length === 0 && (
            <Typography
              style={{
                textAlign: "center",
                width: "100%",
                color: "#bebebe",
                backgroundColor: "transparent",
              }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          )}
          {cartItems.map((item) => {
            const modifiers = item.modifiers.sort(
              (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
            );

            return (
              <React.Fragment key={item.ItemID}>
                <div className="miniCheckoutLayoutContainer">
                  <div className="miniCheckoutLayoutInner">
                    <Grid item xs={12} sx={{ backgroundColor: "transparent" }}>
                      <Typography variant="h6">{item.ItemName}</Typography>
                      {item.modifiers && item.modifiers.length > 0 ? (
                        <Typography variant="h6">Add-On:</Typography>
                      ) : null}
                      {item.modifiers
                        ? modifiers.map((mod) => (
                            <>
                              <Typography component="span" variant="h6">
                                {mod.ItemDescription} - ${" "}
                                {numberWithCommas(mod.DefaultPrice / 100)}
                                <br></br>
                              </Typography>
                            </>
                          ))
                        : null}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{ backgroundColor: "transparent", display: "flex" }}
                    >
                      <Grid
                        container
                        style={{
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          component="span"
                          variant="h6"
                          style={{ textAlign: "end" }}
                        >
                          $ {numberWithCommas(item.DefaultPrice / 100)}
                        </Typography>
                        {item.comments != "Delivery Charge" &&
                        item.comments != "ItemTagMenu" ? (
                          <Box
                            border="1px solid #cecece"
                            borderRadius="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <RemoveIcon
                              style={{ marginLeft: "10px" }}
                              className={classes.buttonCursor}
                              fontSize="normal"
                              onClick={() => {
                                if (item.quantity > 1) {
                                  const newCartItems = cartItems.map(
                                    (cartItem) => {
                                      if (cartItem.ItemID === item.ItemID) {
                                        return {
                                          ...item,
                                          quantity: item.quantity - 1,
                                        };
                                      }

                                      return cartItem;
                                    }
                                  );

                                  dispatch({
                                    type: "SET_CART",
                                    payload: newCartItems,
                                  });
                                } else {
                                  const filterCartItems = cartItems
                                    .map((i) => i)
                                    .filter((j) => j !== item);
                                  dispatch({
                                    type: "SET_CART",
                                    payload: filterCartItems,
                                  });
                                }
                              }}
                            />
                            <Box style={{ margin: "10px" }}>
                              {item.quantity}
                            </Box>
                            <AddIcon
                              style={{ marginRight: "10px" }}
                              className={classes.buttonCursor}
                              fontSize="normal"
                              onClick={() => {
                                const newCartItems = cartItems.map((i) => {
                                  if (i === item) {
                                    return { ...i, quantity: i.quantity + 1 };
                                  }
                                  return i;
                                });

                                dispatch({
                                  type: "SET_CART",
                                  payload: newCartItems,
                                });
                              }}
                            />
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid id="checkoutMiniTotalPrice" style={{ marginTop: "40px" }}>
          <Grid>
            <Typography style={{ color: "#767676" }}>
              <h5>Subtotal</h5>
            </Typography>
            <Typography>
              <h5 style={{ textAlign: "end" }}>
                $ {numberWithCommas(totalPriceItems)}
              </h5>
            </Typography>
          </Grid>
          <Grid>
            <Typography style={{ color: "#767676" }}>
              <h5>Sales Tax (0%)</h5>
            </Typography>
            <Typography>
              <h5 style={{ textAlign: "end" }}>
                ${" "}
                {numberWithCommas(parseFloat((totalPriceItems - discount) * 0))}
              </h5>
            </Typography>
          </Grid>
          <Grid>
            <Typography style={{ fontWeight: "700", fontSize: "1.4rem" }}>
              Amount Due
            </Typography>
            <Typography>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "700",
                  fontSize: "1.5rem",
                }}
              >
                $ {numberWithCommas(parseFloat(totalPriceItems - discount))}
              </h5>
            </Typography>
          </Grid>
          {enableSL === "0" ? (
            <Button
              className={classes.buttonCheckOut}
              style={{ borderRadius: "10px", fontSize: "1.125rem" }}
              onClick={() => {
                history.push({
                  pathname: "/checkout",
                  state: { menuName: props.menuName },
                });
              }}
            >
              Proceed To Checkout
            </Button>
          ) : (
            <Button
              className={classes.buttonCheckOut}
              style={{ borderRadius: "10px", fontSize: "1.125rem" }}
              onClick={() => {
                history.push({
                  pathname: "/checkout-sl",
                  state: { menuName: props.menuName },
                });
              }}
            >
              Proceed To Checkout
            </Button>
          )}
          {cartItems && cartItems.length > 0 && (
            <Button
              className={classes.buttonCheckOut}
              style={{ borderRadius: "10px", fontSize: "1.125rem" }}
              onClick={() =>
                Swal.fire({
                  title: "Store Message",
                  text: "This will remove all items from current order, do you wish to proceed?",
                  icon: "info",
                  showCancelButton: true, // This will show the cancel button
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch({
                      type: "SET_CART",
                      payload: [],
                    });
                    history.push("/welcome");
                  }
                })
              }
            >
              Clear Cart
            </Button>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
