import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const backgroundColor = process.env.REACT_APP_BACKGROUND_COLOR;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const welcomeTitle = process.env.REACT_APP_WELCOME_TITLE;
const aboutUsIntro = process.env.REACT_APP_ABOUTUS_INTRO;
const checkOutMenu = process.env.REACT_APP_CHECKOUT_MENU;

export const ShowCase = () => {
  const history = useHistory();

  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img
            width="100%"
            height="100%"
            title={`${restaurantName} Showcase Image Left`}
            className="img-sm"
            src={siteMedSrc + "about-img-left" + ".png"}
            alt={`${restaurantName} Showcase Image Left`}
            loading="lazy"
          />
        </Col>
        <Col xs={6} md={5}>
          <div className="left margin-top-20">
            <h1 style={{ lineHeight: 1.1 }}>
              Welcome to {restaurantName}. <em>{welcomeTitle}</em>
            </h1>
            <h2 className="margin-top-20">Get to know us</h2>
          </div>
          <div className="left margin-top-20">
            <h5>
              <em>{aboutUsIntro}</em>
            </h5>
          </div>
          <div
            className="showcase-right-text left"
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
              display: "none",
            }}
          >
            <Col xs={6} md={3} className="img-icon">
              <img
                width="100%"
                height="100%"
                title={`${restaurantName} Showcase Icon`}
                src={siteMedSrc + "showcase-icon" + ".png"}
                size="25"
                alt={`${restaurantName} Showcase Icon`}
                loading="lazy"
              />
            </Col>
            <p>
              <h3> Discover Menu </h3>
              <span>{checkOutMenu}</span>
              <br></br>
              <span>
                <Button
                  className="margin-top-10 margin-bottom-10"
                  style={{
                    backgroundColor: buttonColor,
                    color: buttonText,
                    borderColor: buttonColor,
                  }}
                  onClick={() => {
                    history.push("/menu");
                  }}
                >
                  Order Now{" "}
                </Button>
              </span>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
