const config = {
  // Landing Page
  landingcontent: {
    title: "Lake Shafer Boathouse - Monticello",
    subtitle: "Always a good time at Lake Shafer Boathouse - Monticello",
    description:
      "A family restaurant and bar serving pizza's, burgers, sandwiches and more to lake Shafer locals and visitors",
    keywords:
      "Lake Shafer Boathouse, Monticello, family restaurant, bar, pizza, burgers, sandwiches, Heatwave Burger, seasoned waffle fries, dine-in, outdoor seating, takeout, fast service, great cocktails, sports, great beer selection, karaoke, alcohol, beer, cocktails, food, food at bar, hard liquor, wine, good prices, special, happy hour, beautiful place, nice owners, Monticello Friday karaoke, adult slushies, peach slushies, blue raspberry slushies, dinner, drinks, dance, 5658 NW Shafer Dr, Monticello Indiana, Dirty Penny, Steel Country, country music band, free bingo, progressive drawing, ERAS band, 9pm show, Anchored sailors, cocktails and dance, lake Shafer locals, lake Shafer visitors, Monticello events",
  },

  // About Us Page
  aboutcontent: {
    title: "Live music every Saturday night!",
    subtitle: "Always a good time at Lake Shafer Boathouse - Monticello",
    description:
      "🎵 Come for a delicious meal & stay for some great entertainment! 🎵",
    keywords:
      "Lake Shafer Boathouse, Monticello, family restaurant, bar, pizza, burgers, sandwiches, Heatwave Burger, seasoned waffle fries, dine-in, outdoor seating, takeout, fast service, great cocktails, sports, great beer selection, karaoke, alcohol, beer, cocktails, food, food at bar, hard liquor, wine, good prices, special, happy hour, beautiful place, nice owners, Monticello Friday karaoke, adult slushies, peach slushies, blue raspberry slushies, dinner, drinks, dance, 5658 NW Shafer Dr, Monticello Indiana, Dirty Penny, Steel Country, country music band, free bingo, progressive drawing, ERAS band, 9pm show, Anchored sailors, cocktails and dance, lake Shafer locals, lake Shafer visitors, Monticello events",
    header: "Lake Shafer Boathouse - Monticello",
    message:
      "A family restaurant and bar serving steaks, pizzas, burgers, sandwiches and more to Lake Shafer locals and visitors.",
  },
  aboutbusiness: {
    paragraph1:
      "Always a good time at Lake Shafer Boathouse in Monticello, this family restaurant and bar is a favorite among lake Shafer locals and visitors. Serving a delicious variety of pizzas, burgers, and sandwiches, including the popular Heatwave Burger with seasoned waffle fries, the Boathouse offers dine-in, outdoor seating, and takeout options. With fast service, great cocktails, a fantastic beer selection, and lively entertainment like sports and karaoke, it's the perfect spot for a fun night out.",
    paragraph2:
      "Enjoy good prices on cocktails during special and happy hours, and don't miss Monticello's best Friday night karaoke. The beautiful location and friendly owners add to the charm, making every visit memorable. Check out their adult slushies in peach and blue raspberry, and join them for dinner, drinks, and dancing, especially during events like Dirty Penny, Steel Country, and ERAS band performances. Located at 5658 NW Shafer Dr, Monticello, Indiana, the Boathouse promises great food, drinks, and entertainment all year round.",
  },
  littleaboutus: {
    paragraph1:
      "At Lake Shafer Boathouse in Monticello, we're all about creating memorable experiences for families and friends. Our menu is packed with mouthwatering options like pizzas, burgers, and sandwiches, with fan favorites such as the Heatwave Burger paired with seasoned waffle fries. Whether you prefer dine-in, outdoor seating, or takeout, we provide fast and friendly service to ensure every meal is enjoyable. Our great cocktails, impressive beer selection, and lively sports broadcasts make every visit special.",
    paragraph2:
      "We pride ourselves on being a hub of entertainment and community fun. Join us for Monticello's best Friday night karaoke, or enjoy our adult slushies in peach and blue raspberry flavors. Our vibrant atmosphere is perfect for catching up with friends over dinner and drinks, then dancing the night away to live music from bands like Dirty Penny, Steel Country, and ERAS. With special events like free bingo and progressive drawings, there's always something exciting happening at the Boathouse.",
    paragraph3:
      "Located at 5658 NW Shafer Dr, Monticello, Indiana, our beautiful venue, owned by the friendliest hosts, offers more than just great food and drinks. It's a place where good times are made, from happy hour specials to unforgettable nights out. Come and see why we're a favorite among lake Shafer locals and visitors alike – there's always a reason to celebrate at Lake Shafer Boathouse!",
  },

  // Contact Page
  contactcontent: {
    title: "Give us a call at +1 219-253-8433 or come by for a visit!",
    description:
      "Contact us at Lake Shafer Boathouse in Monticello, Indiana, for fast service, great food, refreshing drinks, and unforgettable entertainment, all at 5658 NW Shafer Dr.",
    keywords:
      "Lake Shafer Boathouse, Monticello, family restaurant, bar, pizza, burgers, sandwiches, Heatwave Burger, seasoned waffle fries, dine-in, outdoor seating, takeout, fast service, great cocktails, sports, great beer selection, karaoke, alcohol, beer, cocktails, food, food at bar, hard liquor, wine, good prices, special, happy hour, beautiful place, nice owners, Monticello Friday karaoke, adult slushies, peach slushies, blue raspberry slushies, dinner, drinks, dance, 5658 NW Shafer Dr, Monticello Indiana, Dirty Penny, Steel Country, country music band, free bingo, progressive drawing, ERAS band, 9pm show, Anchored sailors, cocktails and dance, lake Shafer locals, lake Shafer visitors, Monticello events",
    message:
      "A family restaurant and bar serving steaks, pizzas, burgers, sandwiches and more to Lake Shafer locals and visitors.",
  },

  // Photo Gallery Page
  gallerycontent: {
    title:
      "Your taste buds deserve the extraordinary – savor the delight at our ambitious destination",
    description:
      "Explore our photo gallery to see the vibrant atmosphere, delicious food, and exciting events that make Lake Shafer Boathouse in Monticello, Indiana, a favorite destination for locals and visitors.",
    keywords:
      "Explore, Photo Gallery, Lake Shafer Boathouse, Monticello, family restaurant, bar, pizza, burgers, sandwiches, Heatwave Burger, seasoned waffle fries, dine-in, outdoor seating, takeout, fast service, great cocktails, sports, great beer selection, karaoke, alcohol, beer, cocktails, food, food at bar, hard liquor, wine, good prices, special, happy hour, beautiful place, nice owners, Monticello Friday karaoke, adult slushies, peach slushies, blue raspberry slushies, dinner, drinks, dance, 5658 NW Shafer Dr, Monticello Indiana, Dirty Penny, Steel Country, country music band, free bingo, progressive drawing, ERAS band, 9pm show, Anchored sailors, cocktails and dance, lake Shafer locals, lake Shafer visitors, Monticello events",
    message:
      "Browse our photo gallery to experience the fun, delicious food, and lively events at Lake Shafer Boathouse in Monticello, Indiana!",
  },

  // Menu Page
  ordercontent: {
    title:
      "Discover Our Delicious Menu: Pizzas, Burgers, Sandwiches, and More at Lake Shafer Boathouse!",
    description:
      "Order from our menu to enjoy a variety of pizzas, burgers, sandwiches, and more, all crafted with love at Lake Shafer Boathouse in Monticello, Indiana!",
    keywords:
      "Lake Shafer Boathouse, Monticello, family restaurant, bar, pizza, burgers, sandwiches, Heatwave Burger, seasoned waffle fries, dine-in, outdoor seating, takeout, fast service, great cocktails, sports, great beer selection, karaoke, alcohol, beer, cocktails, food, food at bar, hard liquor, wine, good prices, special, happy hour, beautiful place, nice owners, Monticello Friday karaoke, adult slushies, peach slushies, blue raspberry slushies, dinner, drinks, dance, 5658 NW Shafer Dr, Monticello Indiana, Dirty Penny, Steel Country, country music band, free bingo, progressive drawing, ERAS band, 9pm show, Anchored sailors, cocktails and dance, lake Shafer locals, lake Shafer visitors, Monticello events",
    message:
      "Indulge in our delicious selection of pizzas, burgers, and sandwiches at Lake Shafer Boathouse in Monticello, Indiana!",
  },

  // Eloyalty Page
  eloyaltycontent: {
    title:
      "Join our eLoyalty Club for exclusive member benefits, specials, and events, earning loyalty rewards.",
    description:
      "Join our eLoyalty program at Lake Shafer Boathouse to enjoy exclusive rewards, special offers, and updates on our latest events in Monticello, Indiana!",
    keywords:
      "Lake Shafer Boathouse, Monticello, family restaurant, bar, pizza, burgers, sandwiches, Heatwave Burger, seasoned waffle fries, dine-in, outdoor seating, takeout, fast service, great cocktails, sports, great beer selection, karaoke, alcohol, beer, cocktails, food, food at bar, hard liquor, wine, good prices, special, happy hour, beautiful place, nice owners, Monticello Friday karaoke, adult slushies, peach slushies, blue raspberry slushies, dinner, drinks, dance, 5658 NW Shafer Dr, Monticello Indiana, Dirty Penny, Steel Country, country music band, free bingo, progressive drawing, ERAS band, 9pm show, Anchored sailors, cocktails and dance, lake Shafer locals, lake Shafer visitors, Monticello events",
    message:
      "Sign up for our eLoyalty program to receive exclusive rewards, special offers, and event updates from Lake Shafer Boathouse in Monticello, Indiana!",
  },
};

export default config;
